<template>
  <div>
    <div class="bg-white">
      <div class="px-4 pb-4 mb-4">
        <!--Number of cars header BEGIN-->
        <div class="text-medium pt-6 pb-3">
          {{ $t('booking.petsNumber') }}
          <span class="text-small col-2 text-center --c-white px-0 bg-pink rounded-4 py-1 px-2">
            {{$t('reservationAndUsageHistory.mandatory')}}
          </span>
        </div>
        <v-container>
          <v-row>
            <v-col class="text-medium col-5 --c-bg px-0 py-1">
              <v-select
                hide-details
                solo
                :items="petCountList"
                v-model="petCount"
              ></v-select>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-container>
        <div v-for="ind in petCount" :key="ind">
          <div class="bg-smoke text-medium font-weight-bold --c-ms py-2 pl-2 mt-4">
            {{ $t('booking.petInfo') }}{{ ind }}
            <span class="text-small col-2 text-center --c-white px-0 bg-pink rounded-4 py-1 px-2">
              {{$t('reservationAndUsageHistory.mandatory')}}
            </span>
          </div>
          <div class="d-flex align-center">
            <FormLabel :label="$t('booking.petOwnerName')">
              <template v-slot:label>
                <v-checkbox
                  :input-value="getValue(ind - 1, 'repNameOwnerCheck')"
                  dense
                  class="super-dense ml-4"
                  @change="changeRepNameCheck(ind, $event)"
                  label="宿泊代表者と同じ"
                  hide-details
                />
              </template>
            </FormLabel>
          </div>
          <v-text-field
            :value="getValue(ind - 1, 'ownerName')"
            @input="setValue(ind - 1, 'ownerName', $event)"
            :disabled="getValue(ind - 1, 'repNameOwnerCheck')"
            solo
            hide-details
          >
          </v-text-field>
          <FormLabel :label="$t('booking.breeds') + (facilityInfo.catAllowed ? `・${$t('booking.catBreeds')}` : '')" />
          <v-text-field
            :value="getValue(ind - 1, 'breed')"
            @input="setValue(ind - 1, 'breed', $event)"
            solo
            hide-details
          />
          <FormLabel :label="$t('booking.petWeight')" />
          <v-text-field
            :value="getValue(ind - 1, 'petWeight')"
            @input="setValue(ind - 1, 'petWeight', $event, Number)"
            type="number"
            solo
            hide-details="auto"
            suffix="kg"
            :error="weightRestrictionError"
            :rules="[$rules.minInt(1)]"
            @blur="checkWeightRestriction"
          />
          <div v-if="facilityInfo.hasPetWeightRestriction">
            25キロ以上のペットをお連れの場合は、1室あたり1頭までしかお連れすることができません
          </div>
        </div>
      </div>
      <!--Pet lot format END-->
    </div>
  </div>
</template>

<script>
import FormLabel from '../Inputs/FormLabel'
import { range } from '@/utils/generator'

export default {
  name: 'PetInformation',
  components: { FormLabel },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    facilityInfo: Object,
    repName: String,
    allowZero: Boolean,
    roomType: Object,
  },
  mounted () {
    if (this.value === undefined) {
      this.emitInput({})
    }
  },
  methods: {
    emitInput (pets) {
      const weightRestrictionValid = this.validatePetWeights(pets.pets)
      const allValid = pets.pets?.every(p => p.valid)
      this.$emit('input', {
        ...pets,
        allValid: allValid && weightRestrictionValid
      })
    },
    getValue (ind, prop) {
      return this.value.pets[ind][prop]
    },
    setValue (ind, prop, value, construct) {
      const pet = this.value.pets[ind]
      pet[prop] = construct ? construct(value) : value
      pet.valid = !!(pet.breed && pet.ownerName && pet.petWeight)
      const newPets = [...this.value.pets]
      newPets.splice(ind, 1, pet)

      this.emitInput({
        ...this.value,
        pets: newPets
      })
    },
    setRepNameAsOwner (ind) {
      this.setValue(ind, 'ownerName', this.repName)
    },
    validatePetWeights (pets) {
      if (!pets) return false
      return !this.facilityInfo.hasPetWeightRestriction ||
        pets.every(p => !p.petWeight || p.petWeight < 25) ||
        pets.length === 1
    },
    checkWeightRestriction () {
      this.weightRestrictionError = !this.validatePetWeights(this.value.pets)
    },
    changeRepNameCheck (ind, checked) {
      this.setValue(ind - 1, 'repNameOwnerCheck', checked)
      if (checked) {
        this.setRepNameAsOwner(ind - 1)
      }
    }
  },
  data () {
    return {
      weightRestrictionError: false
    }
  },
  computed: {
    max () {
      return this.roomType.maxPetCount ?? this.facilityInfo.maxPetCount ?? 2
    },
    petCount: {
      get () {
        return this.value?.pets?.length ?? 0
      },
      set (newNumber) {
        this.emitInput({
          ...this.value,
          pets: [
            ...(this.value?.pets ?? []),
            ...[...Array(newNumber)].map(() => ({}))
          ].slice(0, newNumber)
        })
      }
    },
    petCountList () {
      return range(this.allowZero ? 0 : 1, this.max)
    }
  },
  watch: {
    weightRestrictionError (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$toast.error('２５キロ以上のペットがいる場合、１部屋に同伴できるのは１頭のみとなります。')
      }
    }
  }
}
</script>
