<template>
  <div>
    <div class="word-break --red mb-4">
      こちらのフォームで再度ペット情報をご登録いただくと、登録済みのペット情報が削除されますのでご注意ください。すでにご登録頂いているペットの情報も再度こちらのフォームにご入力ください。
    </div>
    <template>
      <PetPolicy v-model="petPolicyAgreed" :facilityInfo="facility" :hideForm="changeToNoPet">
        <template v-slot:beforeForm>
          <div>
            <v-checkbox v-model="changeToNoPet" label="同伴なしに変更する" />
          </div>
        </template>
      </PetPolicy>
      <PetInformation
        v-if="!changeToNoPet && petPolicyAgreed"
        v-model="pets"
        :facilityInfo="facility"
        :repName="repName"
        :roomType="roomType"
      />
    </template>
    <v-btn
      rounded
      block
      height="66"
      @click="change"
      :disabled="!canSave"
      class="text-title bg-primary --c-white mb-3"
    >
      {{ $t("reservationAndUsageHistory.changeToTheAboveContent") }}
    </v-btn>
  </div>
</template>

<script>
import { selectedBookingComputed } from '../../selected-booking.mixin'
import PetPolicy from '@/components/ReservationComponents/PetPolicy.vue'
import PetInformation from '@/components/ReservationComponents/PetInformation.vue'
import { formatPetInfo } from '@/utils/pet'

export default {
  name: 'PetInformationChange',
  mixins: [selectedBookingComputed],
  components: {
    PetPolicy,
    PetInformation
  },
  methods: {
    async change () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('updatePetInfo', {
            bookingId: this.id,
            petInfo: formatPetInfo(this.pets.pets)
          })
        })
      })

      this.completeChange()
    }
  },
  data () {
    return {
      pets: { pets: [] },
      petPolicyAgreed: false,
      changeToNoPet: false
    }
  },
  computed: {
    canSave () {
      return (this.changeToNoPet && this.pets.pets.length === 0) || (this.petPolicyAgreed && this.pets.allValid)
    },
    repName () {
      return this.representativeGuest?.name || ''
    }
  },
  watch: {
    changeToNoPet (v) {
      if (v) {
        this.pets = { pets: [] }
      }
    }
  }
}
</script>
