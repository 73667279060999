<template>
  <div>
    <div class="mb-2">
      以下のフォームは{{facilityInfo.name}}宿泊約款第２条の２に定める同意書とされ、本同意書の情報を当社が受け取った時点で提出されたことになります。
    </div>
    <slot name="beforeForm" />
    <div class="bg-white pa-4 mb-4" v-if="!hideForm">
      <!-- below is deleted as per https://kujira.backlog.com/view/TO2020-653#comment-220291413 -->
      <!-- <span class="--c-red">東急バケーションズ愛犬家族 利用規則に同意される場合は、チェックボックスにチェックをお入れください。</span> -->
      <v-checkbox
        :label="`${ facilityInfo.name }の利用に関して、東急バケーションズが定める愛犬${cat}家族 利用規則を遵守することに同意します。`"
        :readonly="!termViewed"
        :value="true"
        :input-value="value"
        @change="emitInput"
        @click="click"
      />
      東急バケーションズ愛犬{{ cat }}家族 利用規則は<a :href="facilityInfo.petTermUrl" target="_blank" @click="termViewed = true">こちら</a>から確認できます。
    </div>
  </div>
</template>

<script>

export default {
  name: 'PetPolicy',
  props: {
    value: Boolean,
    facilityInfo: Object,
    hideForm: Boolean
  },
  data () {
    return {
      termViewed: false
    }
  },
  computed: {
    cat() {
      if (this.facilityInfo.catAllowed) {
        return '・愛猫'
      }
      return ''
    }
  },
  methods: {
    emitInput (v) {
      this.$emit('input', v)
    },
    click () {
      if (!this.termViewed) {
        this.$toast.error(`東急バケーションズ愛犬${this.cat}家族 利用規則へのリンクをクリックし、ご確認いただかないとチェックはつけられません。`)
      }
    }
  }
}
</script>
